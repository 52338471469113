import React, { FC } from "react"
import SwiperCore, { Scrollbar, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container } from "components/Shared/Container"
import styled from "styled-components"
import { spacing } from "styles/spacing"
import { typography } from "styles/typography"
import { breakpoint } from "styles/breakpoints"

interface ITestimonailsProps {
  testimonials: [{ text: string }]
}

SwiperCore.use([Scrollbar, Autoplay])

const Wrapper = styled.div`
  & p {
    font-size: ${typography.size.sm};
    margin: ${spacing.sm}px;
    max-width: 100%;
    @media ${breakpoint.md} {
      font-size: ${typography.size.md};
    }
  }
`

const Testimonails: FC<ITestimonailsProps> = ({ testimonials }) => {
  if (!testimonials) return null
  return (
    <Container>
      <Swiper
        loop
        speed={3500}
        autoplay={{
          delay: 2000,
        }}
        scrollbar={{ draggable: true }}
        slidesPerView={1}
      >
        {testimonials.map(testimonial => {
          return (
            <SwiperSlide
              key={testimonial.text}
              style={{
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Wrapper dangerouslySetInnerHTML={{ __html: testimonial.text }} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Container>
  )
}

export default Testimonails
