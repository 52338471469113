import React, { FC, useState } from "react"
import styled from "styled-components"
import TextareaAutosize from "react-textarea-autosize"
import { breakpoint } from "styles/breakpoints"
import { spacing } from "styles/spacing"
import FeaturedSlides from "components/Slider/FeaturedSlides"
import { Container, ContentContainer } from "components/Shared/Container"
import { Link, useStaticQuery, graphql } from "gatsby"
import { typography } from "styles/typography"
import { colors } from "styles/colors"
import { SendIcon } from "components/Icons"
import { IFeaturedContentProps } from "types"
import SEO from "components/Seo"
import Testimonails from "components/Slider/Testimonial"

interface IFrontPageProps {
  wpPage: {
    id: string
    title: string
    seo: {
      metaDesc: string
      metaKeywords: string[]
      opengraphDescription: string
      opengraphImage: {
        sourceUrl: string
      }
      title: string
      twitterDescription: string
      twitterTitle: string
      canonical: string
      breadcrumbs: [
        {
          text: string
          url: string
        }
      ]
    }
    frontPage: {
      featured: IFeaturedContentProps[]
      heroMovie: {
        mediaItemUrl: string
      }
      links: [
        {
          id: string
          link: string
          title: string
          __typename: string
        }
      ]
      testimonial: [{ text: string }]
    }
  }
}

const WrapperVideo = styled.div`
  width: 100%;
  position: relative;
  display: block;
  margin-top: -100px;
  height: 100vh;
  overflow: hidden;
  @media ${breakpoint.md} {
    margin-bottom: 0 !important;
  }
`

const StyledVideo = styled.video`
  position: absolute;
  min-height: 100%;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const WrapperLinks = styled(Container)`
  display: flex;
  flex-direction: column;
  a {
    font-family: ${typography.heading};
    font-size: ${typography.size.xxl};
    font-weight: bold;
    text-transform: uppercase;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    line-height: 1;
    &:hover {
      opacity: 0.5;
    }
    @media ${breakpoint.md} {
      font-size: 80px;
    }
    @media ${breakpoint.lg} {
      font-size: 120px;
    }
    @media ${breakpoint.xl} {
      font-size: 170px;
    }
    @media ${breakpoint.xxl} {
      font-size: 190px;
    }
    @media (min-width: 1920px) {
      font-size: 250px;
    }
  }
  a:nth-child(even) {
    text-align: right;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
  font-family: ${typography.heading};
  font-size: ${typography.size.md};
  font-weight: bold;
  @media ${breakpoint.md} {
    font-size: 28px;
  }
  @media ${breakpoint.lg} {
    font-size: ${typography.size.xl};
  }
  @media ${breakpoint.xl} {
    font-size: ${typography.size.xxl};
  }
  & > :not(:last-child) {
    margin-bottom: ${spacing.sm}px;
  }
  & > :not(:first-child) {
    margin-bottom: 0px;
  }
  & label {
    width: 100%;
  }
  & input {
    border: 1px solid white !important;
    margin-top: ${spacing.xs}px;
    display: block;
    margin-bottom: ${spacing.xs}px;
    padding: 2px 4px;
    width: 100%;
    color: ${colors.grey};
  }
  & textarea {
    margin-top: ${spacing.xs}px;
    border: 1px solid white !important;
    width: 100%;
    padding: 2px 4px;
    color: ${colors.grey};
  }
  button {
    background-color: ${colors.white};
    margin-top: ${spacing.sm}px;
    color: ${colors.black};
    border: none;
    align-self: flex-end;
    display: flex;
    padding: ${spacing.sm}px ${spacing.sm}px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    @media ${breakpoint.md} {
      padding: ${spacing.md}px ${spacing.md}px;
    }
    & svg {
      margin-left: ${spacing.xs}px;
      height: 20px;
      width: 20px;
      @media ${breakpoint.md} {
        mheight: 24px;
        width: 24px;
      }
      @media ${breakpoint.lg} {
        margin-left: ${spacing.sm}px;
        height: 32px;
        width: 32px;
      }
    }
  }
  p {
    color: ${colors.error};
  }
  button :hover {
    background-color: transparent;
    color: ${colors.white};
  }
`

const WrapperSuccess = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  & p {
    background: ${colors.black};
    padding: ${spacing.md}px;
    font-family: ${typography.heading};
    font-weight: 800;
    text-transform: uppercase;
    font-size: ${typography.size.lg};
  }
`

const Index: FC = () => {
  const { wpPage }: IFrontPageProps = useStaticQuery(graphql`
    query FrontPageQuery {
      wpPage(isFrontPage: { eq: true }) {
        id
        title
        seo {
          metaDesc
          metaKeywords
          opengraphDescription
          opengraphImage {
            sourceUrl
          }
          title
          twitterDescription
          twitterTitle
          canonical
          breadcrumbs {
            text
            url
          }
        }
        frontPage {
          testimonial {
            text
          }
          featured {
            ... on WpEvent {
              id
              excerpt
              featuredImage {
                node {
                  sourceUrl
                }
              }
              link
              nodeType
              title
              postTypes {
                date
              }
            }
            ... on WpPost {
              id
              excerpt
              featuredImage {
                node {
                  sourceUrl
                }
              }
              link
              nodeType
              title
            }
            ... on WpPage {
              id

              featuredImage {
                node {
                  sourceUrl
                }
              }
              link
              nodeType
              title
            }
            ... on WpFilm {
              id
              link
              nodeType
              slug
              title
              nodeType
              film {
                film
              }
              postTypes {
                date
              }
            }
            ... on WpPhoto {
              id
              featuredImage {
                node {
                  sourceUrl
                }
              }
              link
              slug
              title
              postTypes {
                date
              }
              photos {
                photos {
                  image {
                    id
                    sourceUrl
                  }
                }
              }
              nodeType
            }
          }
          heroMovie {
            mediaItemUrl
          }
          links {
            ... on WpPage {
              id
              title
              link
            }
          }
        }
      }
    }
  `)

  const [form, setForm] = useState({
    name: "",
    mail: "",
    message: "",
    error: "",
    succes: false,
  })

  const handleChange = e => {
    const { name, value } = e.target
    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...form }),
    })
      .then(() => {
        setForm(() => ({
          message: "",
          name: "",
          mail: "",
          error: "",
          succes: true,
        }))

        setTimeout(() => {
          setForm(prevState => ({
            ...prevState,
            succes: false,
          }))
        }, 1000)
      })
      .catch(error =>
        setForm(prevState => ({
          ...prevState,
          error: error,
        }))
      )
  }

  return (
    <>
      <SEO
        title={wpPage.title}
        description={wpPage.seo.metaDesc}
        image={wpPage.seo.opengraphImage.sourceUrl}
      />
      <WrapperVideo>
        <StyledVideo
          autoPlay
          muted
          loop
          playsInline
          poster="https://media.admin.mightyevent.se/2020/10/might-event-logo-sm.png"
        >
          <source
            src={wpPage.frontPage.heroMovie.mediaItemUrl}
            type="video/mp4"
          ></source>
        </StyledVideo>
      </WrapperVideo>
      <ContentContainer style={{ maxWidth: 1920 }}>
        {Boolean(wpPage.frontPage.featured.length) ? (
          <FeaturedSlides
            featured={wpPage.frontPage.featured}
            breakpoints={{
              768: { slidesPerView: 2, spaceBetween: spacing.lg },
              1024: { slidesPerView: 3, spaceBetween: spacing.xl },
            }}
          />
        ) : null}
        <WrapperLinks>
          {wpPage.frontPage.links.map(link => {
            return (
              <Link
                key={link.id}
                to={`${link.link}`}
                aria-label={`go to ${link.link}`}
                data-sal="zoom-in"
                data-sal-easing="ease"
              >
                {link.title}
              </Link>
            )
          })}
        </WrapperLinks>
        <Container>
          <StyledForm
            onSubmit={handleSubmit}
            id="contact-form"
            name="contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="contact" value="contact" />
            <p style={{ height: 0, overflow: "hidden" }}>
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </p>
            <label htmlFor="name">
              Namn:
              <input
                type="text"
                name="name"
                required
                value={form.name}
                onChange={handleChange}
              />
            </label>
            <label htmlFor="mail">
              Mail:
              <input
                type="email"
                name="mail"
                required
                onChange={handleChange}
                value={form.mail}
              />
            </label>
            <label htmlFor="message">
              Vad kan vi hjälpa er med?
              <TextareaAutosize
                name="message"
                required
                value={form.message}
                onChange={handleChange}
              />
            </label>
            {Boolean(form.error.length) ? <p>{form.error}</p> : null}
            <button type="submit">
              Skicka
              <SendIcon />
            </button>
          </StyledForm>
          {wpPage.frontPage.testimonial ? (
            <Testimonails testimonials={wpPage.frontPage.testimonial} />
          ) : null}
        </Container>
        {form.succes ? (
          <WrapperSuccess>
            <p>Meddelandet skickades</p>
          </WrapperSuccess>
        ) : null}
      </ContentContainer>
    </>
  )
}

export default Index
